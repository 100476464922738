import { RecruitersList } from "./List/List";
import { RecruitersEdit } from "./Edit/Index";
import { JobShow } from "./Show/Index";

const RecruitersResource = {
  List: RecruitersList,
  Edit: RecruitersEdit,
  Show: JobShow,
};

export default RecruitersResource;
