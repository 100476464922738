import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
  FunctionField,
  useRedirect,
} from "react-admin";
import { EmptyList } from "../../General/views/EmptyList";
import { StatusField } from "../../General/utils/StatusField";

export const Broadcasts = (props) => {
  const redirect = useRedirect();
  return (
    <ReferenceManyField
      reference="recruiter/broadcasts"
      target="customer_id"
      addLabel={false}
    >
      <Datagrid
        empty={<EmptyList itemName={"therapist"} {...props} />}
        rowClick={(id) => redirect(`/jobs/${id}/show`)}
        optimized
      >
        <TextField source="id" />
        <TextField source="user_applied_count" label="Users Appliad" />
        <DateField source="createdAt" />
        <FunctionField
          label="Status"
          source="status"
          render={(record) => <StatusField status={record.status} />}
        />
      </Datagrid>
    </ReferenceManyField>
  );
};
