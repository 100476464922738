import { useState } from "react";
import { BasicDetails } from "./BasicDetails";
// import { EmpHistory } from "./EmpHistory";
import { EditTabs } from "../../General/utils/EditTabs";
import { useAuthenticated, Edit, FormWithRedirect } from "react-admin";
import { Container, createStyles, makeStyles } from "@material-ui/core";
import { EditBottomToolBar } from "../../General/utils/EditBottomToolBar";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
  })
);

const validatePasswords = ({ password, confirm_password }) => {
  const errors = {};

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password =
      "The password confirmation is not the same as the password.";
  }
  return errors;
};

export const DriversEdit = (props) => {
  useAuthenticated();
  const classes = useStyles();
  const tabsWithDetails = {
    basic_details: {
      name: "Applicant Details",
      component: <BasicDetails />,
    },

    // emp_history: {
    //   name: "Employment History",
    //   component: <EmpHistory />,
    // },
  };
  const tabIds = Object.keys(tabsWithDetails);
  const [currentTabId, setCurrentTabId] = useState(tabIds[0]);

  return (
    <Edit
      className={classes.root}
      title="Applicant Details"
      mutationMode="pessimistic"
      {...props}
    >
      <FormWithRedirect
        {...props}
        validate={validatePasswords}
        render={(formProps) => [
          <EditTabs
            key="tabs"
            tabsWithDetails={tabsWithDetails}
            setCurrentTabId={setCurrentTabId}
            currentTabId={currentTabId}
          />,
          <Container key="content">
            {tabsWithDetails[currentTabId].component}
          </Container>,
          currentTabId !== "emp_history" ? (
            <EditBottomToolBar key="toolbar" {...formProps} />
          ) : null,
        ]}
      />
    </Edit>
  );
};
