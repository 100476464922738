import { FunctionField } from "react-admin";
import { Typography, createStyles, makeStyles, Grid } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: 90,
      height: 90,
      margin: "auto",
    },
    colorTextSecondary: {
      color: "white",
      marginTop: "30px",
      marginBottom: "40px",
    },
    colorWhite: {
      color: "white",
    },
    colorTextgrey: {
      color: "#ffffff",
    },
    editinputs: {
      width: "100%",
    },
    ptb: {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },
    wrapIcon: {
      verticalAlign: "middle",
      display: "inline-flex",
      color: "#e0d9d9",
      alignItems: "center",
    },
    check: {
      color: "#1DC3A7",
      marginRight: "10px",
    },
    fs18: {
      fontSize: "18px",
    },
    divider: {
      width: "100%",
      borderTop: "1px solid #ededed",
    },
  })
);

export const Logs = (props) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid style={{ paddingBottom: "40px" }} item xs={5}>
        <Typography
          style={{ fontSize: "18px" }}
          variant="h6"
          className={classes.colorTextgrey}
        >
          Creation Time
        </Typography>
      </Grid>
      <Grid style={{ paddingBottom: "40px" }} item xs={6}>
        <FunctionField
          variant="h6"
          style={{ fontSize: "18px" }}
          className={classes.colorWhite}
          label=" "
          render={(record) =>
            record?.creation_time
              ? moment(record?.creation_time).format("MMMM Do YYYY, h:mm:ss A")
              : ""
          }
        />
      </Grid>

      <Grid style={{ paddingBottom: "40px" }} item xs={5}>
        <Typography
          style={{ fontSize: "18px" }}
          variant="h6"
          className={classes.colorTextgrey}
        >
          Booking Time
        </Typography>
      </Grid>
      <Grid style={{ paddingBottom: "40px" }} item xs={6}>
        <FunctionField
          style={{ fontSize: "18px" }}
          variant="h6"
          className={classes.colorWhite}
          label=" "
          render={(record) =>
            record?.booking_time
              ? moment(record?.booking_time).format("MMMM Do YYYY, h:mm:ss A")
              : ""
          }
        />
      </Grid>

      <Grid style={{ paddingBottom: "40px" }} item xs={5}>
        <Typography
          style={{ fontSize: "18px" }}
          variant="h6"
          className={classes.colorTextgrey}
        >
          Arrival Time
        </Typography>
      </Grid>
      <Grid style={{ paddingBottom: "40px" }} item xs={6}>
        <FunctionField
          style={{ fontSize: "18px" }}
          variant="h6"
          className={classes.colorWhite}
          label=" "
          render={(record) =>
            record?.arrival_time
              ? moment(record?.arrival_time).format("MMMM Do YYYY, h:mm:ss A")
              : ""
          }
        />
      </Grid>

      <Grid style={{ paddingBottom: "40px" }} item xs={5}>
        <Typography
          style={{ fontSize: "18px" }}
          variant="h6"
          className={classes.colorTextgrey}
        >
          Starting Time
        </Typography>
      </Grid>
      <Grid style={{ paddingBottom: "40px" }} item xs={6}>
        <FunctionField
          style={{ fontSize: "18px" }}
          variant="h6"
          className={classes.colorWhite}
          label=" "
          render={(record) =>
            record?.starting_time
              ? moment(record?.starting_time).format("MMMM Do YYYY, h:mm:ss A")
              : ""
          }
        />
      </Grid>

      <Grid style={{ paddingBottom: "40px" }} item xs={5}>
        <Typography
          style={{ fontSize: "18px" }}
          variant="h6"
          className={classes.colorTextgrey}
        >
          Completion Time
        </Typography>
      </Grid>
      <Grid style={{ paddingBottom: "40px" }} item xs={6}>
        <FunctionField
          style={{ fontSize: "18px" }}
          variant="h6"
          className={classes.colorWhite}
          label=" "
          render={(record) =>
            record?.completion_time
              ? moment(record?.completion_time).format(
                  "MMMM Do YYYY, h:mm:ss A"
                )
              : ""
          }
        />
      </Grid>
    </Grid>
  );
};
