import { useState, useEffect } from "react";
import moment from "moment";
import { Cards } from "./Cards";
import { Grid, Typography, TextField } from "@material-ui/core";
import { useDataProvider, useNotify } from "react-admin";
import {
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const Dashboard = (props) => {
  const [selectedFromDate, handleFromDateChange] = useState(
    moment(moment().add(-7, "d")).format(`YYYY-MM-DD`)
  );
  const [selectedToDate, handleToDateChange] = useState(
    moment().format(`YYYY-MM-DD`)
  );
  const [dashboradData, setDashboradData] = useState(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    async function fetchCustomersInsights() {
      const response = await dataProvider
        .getOne("dashboard", {
          id: `dashboardInsights?from=${selectedFromDate}&to=${selectedToDate}`,
          params: {
            from: selectedFromDate,
            to: selectedToDate,
          },
        })
        .catch((e) => {
          notify("Couldn't fetch data for dashboard");
        });
      if (response) {
        setDashboradData(response.data);
      }
    }
    fetchCustomersInsights();
  }, [selectedFromDate, selectedToDate, dataProvider, notify]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} id="react-admin-title">
        <Typography variant="h6">Dashboard</Typography>
      </Grid>

      <Grid item xs={4}>
        <Cards
          raised
          heading={"Recruiters"}
          figure={dashboradData?.total_recruiters ?? "..."}
        />
      </Grid>

      <Grid item xs={4}>
        <Cards
          raised

          heading={"Applicants"}
          figure={dashboradData?.total_applicants ?? "..."}

        />
      </Grid>

      <Grid item xs={4}>
        <Cards
          raised
          heading={"Total Sales"}
          figure={`$${dashboradData?.total_sales?.toFixed(2)}` ?? "..."}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="date"
          label="From"
          type="date"
          defaultValue={selectedFromDate}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            handleFromDateChange(moment(e.target.value).format(`YYYY-MM-DD`))
          }
        />
        <TextField
          id="date"
          label="To"
          type="date"
          defaultValue={selectedToDate}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            handleToDateChange(moment(e.target.value).format(`YYYY-MM-DD`))
          }
        />
      </Grid>

      <ResponsiveContainer width="100%" aspect={3}>
        <LineChart
          height={600}
          data={dashboradData?.earnings}
          margin={{ top: 60, right: 60, left: 0, bottom: 10 }}
        >
          <CartesianGrid />
          <XAxis dataKey="date" interval={"preserveStartEnd"} />
          <YAxis dataKey="earning" />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="earning"
            strokeWidth={3}
            stroke="#673de6"
            isAnimationActive={false}
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>

      <ResponsiveContainer width="100%" aspect={3}>
        <LineChart
          height={600}
          data={dashboradData?.new_users}
          margin={{ top: 60, right: 60, left: 0, bottom: 10 }}
        >
          <CartesianGrid />
          <XAxis dataKey="date" interval={"preserveStartEnd"} />
          <YAxis dataKey="user" />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="user"
            strokeWidth={3}
            stroke="#673de6"
            isAnimationActive={false}
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Grid>
  );
};

export default Dashboard;
