import { Modal, Box, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Lightbox } from "react-modal-image";

export const ImageModal = ({ src, onClose, alt = "" }) => {
  return (
    <Modal
      open
      onClose={onClose}
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        style={{
          textAlign: "center",
          paddingBottom: "120px",
          paddingTop: "120px",
        }}
        sx={{
          width: 300,
          height: 300,
          backgroundColor: "primary.dark",
          "&:hover": {
            backgroundColor: "primary.main",
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      >
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <Lightbox
          medium={src}
          large={src}
          alt={alt.replace("View ", "")}
          onClose={onClose}
        />
      </Box>
    </Modal>
  );
};
