import {
  Box,
  Grid,
  makeStyles,
  Typography,
  createStyles,
} from "@material-ui/core";
import { TextInput } from "react-admin";

const useStyles = makeStyles((theme) =>
  createStyles({
    index: {
      display: "none",
    },
    accordion_details: {
      display: "block",
      padding: "0",
      borderBottom: "0 !important",
      "& .MuiAccordionSummary-root": {
        padding: "0",
        maxWidth: "150px",
      },
    },
    colorTextgrey: {
      color: "#ffffff",
    },
    ptb: {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },
    root: {
      "& .MuiSelect-root, .MuiInputBase-input": {
        paddingLeft: "0",
        paddingRight: "0",
        marginTop: "10px",
        color: "#ffffff",
      },
      "& .MuiFilledInput-underline": {
        "&:before": {
          borderBottom: "1px solid #ffffff",
        },
      },
    },
  })
);

export const BasicDetails = (props) => {
  const classes = useStyles();
  return [
    <Box component="section" className={`${classes.root} myforms`}>
      <Typography style={{ paddingBottom: "60px" }}>
        {/* Account Information */}
      </Typography>

      <Grid style={{ width: "100%" }} container spacing={10}>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="first_name" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="last_name" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="email" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="phone" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="state" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="city" />
        </Grid>
        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <TextInput fullWidth source="zip_code" />
        </Grid>
      </Grid>

      <Grid
        style={{ width: "100%", paddingBottom: "50px" }}
        container
        spacing={10}
      >
        <Grid
          style={{ width: "100%", paddingBottom: "50px" }}
          className={classes.ptb}
          item
          xl={12}
          md={12}
          sm={12}
        ></Grid>
      </Grid>
    </Box>,
  ];
};
