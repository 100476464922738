import { useState } from "react";
import {
  Button,
  Confirm,
  useNotify,
  useRefresh,
  useDataProvider,
} from "react-admin";
import { Grid } from "@material-ui/core";
import { StatusField } from "../utils/StatusField";

export const StatusButtons = ({
  data: record,
  basePath,
  data,
  resource,
  ...props
}) => {
  const title = "Are you sure to update this entity ?";
  const content = "The updation will not affect already booked sessions.";
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [onConfirmAction, setOnConfirmAction] = useState(null);
  const toggleClick = (state = !open) => {
    setOpen(state);
  };

  const confirmUpdate = () => {
    dataProvider
      .update(`${resource}/status`, {
        id: record.id,
        data: { type: onConfirmAction },
      })
      .then((response) => {
        refresh();
        notify("Status Updated!");
      })
      .catch((error) => notify(`Unknown error occurred`, "warning"));
    setOnConfirmAction(null);
    setOpen(false);
  };

  const update = (type) => {
    // confirm to update
    setOnConfirmAction(type);
    setOpen(true);
  };

  const { scrutinization = {} } = data ?? {};

  const hasFailed = () => {
    return (
      scrutinization.interview === "fail" ||
      scrutinization.certification === "fail" ||
      scrutinization.background_check === "fail"
    );
  };

  const hasPassed = () => {
    return (
      scrutinization.interview === "pass" &&
      scrutinization.certification === "pass" &&
      scrutinization.background_check === "pass"
    );
  };

  const CancelButton = () => {
    const allowedStatusesForCancel = [
      "active",
      "accepted",
      "provider_on_the_way",
      "provider_arrived",
    ];

    if (
      resource === "jobs" &&
      allowedStatusesForCancel.includes(record?.status)
    ) {
      return (
        <Button
          key="cancel-button"
          onClick={() => update("cancel")}
          label="CANCEL"
          className="btnred"
        />
      );
    } else {
      return null;
    }
  };

  const ApproveButton = () => {
    if (
      (resource === "admins" && record?.status !== "active") ||
      (resource === "studios" && record?.status !== "active") ||
      (resource === "customers" && record?.status !== "active") ||
      ((resource === "therapists" || resource === "studio-therapists") &&
        hasPassed() &&
        record?.status !== "active")
    ) {
      return (
        <Button
          key="approve-button"
          onClick={() => update("approve")}
          label="APPROVE"
          className="btngreen"
        />
      );
    } else {
      return null;
    }
  };

  const BlockButton = () => {
    if (
      (resource === "admins" && record?.status !== "blocked") ||
      (resource === "studios" && record?.status !== "blocked") ||
      (resource === "customers" && record?.status !== "blocked") ||
      ((resource === "therapists" || resource === "studio-therapists") &&
        hasFailed() &&
        record?.status !== "blocked")
    ) {
      return (
        <>
          {/* <Button
            key="block-button"
            onClick={() => update("block")}
            label="BLOCK"
            className="btnred"
          /> */}
        </>
      );
    } else {
      return null;
    }
  };

  const OnHoldButton = () => {
    if (
      (resource === "admins" && record?.status !== "on_hold") ||
      (resource === "studios" && record?.status !== "on_hold") ||
      (resource === "customers" && record?.status !== "on_hold") ||
      ((resource === "therapists" || resource === "studio-therapists") &&
        record?.status !== "on_hold")
    ) {
      return (
        <>
          {/* <Button
            key="on-hold-button"
            onClick={() => update("on_hold")}
            label="ON HOLD"
            className="btngreen"
          /> */}
        </>
      );
    } else {
      return null;
    }
  };

  const ResolveButton = () => {
    if (resource === "tickets" && record?.status === "Active") {
      return (
        <Button
          key="resolve-button"
          onClick={() => update("Resolved")}
          label="RESOLVED"
          className="btngreen"
        />
      );
    } else {
      return null;
    }
  };

  const OpenActive = () => {
    if (resource === "tickets" && record?.status === "Resolved") {
      return (
        <Button
          key="open-button"
          onClick={() => update("Active")}
          label="OPEN"
          className="btnred"
        />
      );
    } else {
      return null;
    }
  };

  return [
    resource !== "tickets" ? (
      <Grid key="status-buttons-text" item xs={9}>
        <Grid container justify="flex-end">
          <StatusField status={record?.status} variant={"h5"} />
        </Grid>
      </Grid>
    ) : null,

    <Grid key="status-buttons-btn" item xs={3}>
      <Grid container justify="flex-end">
        {[
          CancelButton(),
          ApproveButton(),
          OnHoldButton(),
          BlockButton(),
          ResolveButton(),
          OpenActive(),
          <Confirm
            key={`confirmation-popup`}
            isOpen={open}
            title={title}
            content={content}
            onConfirm={confirmUpdate}
            onClose={() => toggleClick(false)}
          />,
        ]}
      </Grid>
    </Grid>,
  ];
};
