import { Card, CardContent, Typography } from "@material-ui/core";

export const Cards = ({ heading, figure, secondary_text }) => (
  <Card sx={{ width: 275 }}>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
        {heading}
      </Typography>

      <Typography variant="h5" component="div">
        {figure}
      </Typography>

      <Typography sx={{ mb: 1.5 }} color="secondary"></Typography>

      {Array.isArray(secondary_text) ? (
        secondary_text.map((text) => (
          <Typography variant="body1">{text}</Typography>
        ))
      ) : (
        <Typography variant="body1">{secondary_text}</Typography>
      )}
    </CardContent>
  </Card>
);
