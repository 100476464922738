import {
  Button,
  Paper,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import { useRedirect } from "react-admin";
import { AvatarWithInput } from "../utils/AvatarWithInput";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      textAlign: "center",
      width: "270px",
      marginLeft: "0",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(10),
      "@media (max-width:1280px)": {
        width: "217px",
        marginLeft: "5px",
      },
    },
    avatar: {
      width: 90,
      height: 90,
      margin: "auto",
      marginBottom: theme.spacing(5),
    },
    button: {
      marginTop: theme.spacing(5),
    },
    colorDark: {
      color: "#202020",
    },
    colorTextgrey: {
      color: "#202020",
    },
  })
);

export const AsideCustomerProfile = ({ customer, show_minimum = false }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const {

    company_id,

    company_name = "",
    company_logo = "",
    description = "",
    job_image_url = "",
    created_at = new Date(),
  } = customer ?? {};

  return (
    <Paper className={classes.paper} square={true}>
      <Typography
        gutterBottom={true}
        paragraph={true}
        variant="h6"
        align="center"
        className={classes.colorTextgrey}
      >
        {description ? "Broadcast Image" : "Company Info"}
      </Typography>

      <AvatarWithInput
        id={company_id}
        resource="customers"
        default_source={company_logo || job_image_url}
        name={company_name || description}
        is_editable
      />

      <Typography gutterBottom={true} variant="h6">
        {company_name || description}
      </Typography>

      {!show_minimum ? (
        [
          <Typography
            key={`member-since-${customer.id}`}
            variant="body2"
            className={classes.colorTextgrey}
          >
            Member Since {moment(created_at).format("LL")}
          </Typography>,
        ]
      ) : (
        <Button
          className={classes.button}
          style={{ display: "none" }}
          onClick={() => redirect(`/staff/${customer.id}`)}
          label="View Profile"
        />
      )}
    </Paper>
  );
};
