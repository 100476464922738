import { Fragment, useCallback } from "react";
import {
  List,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  useListContext,
  useAuthenticated,
  ListContextProvider,
} from "react-admin";
import Filters from "./Filters";
import { Divider } from "@material-ui/core";
import { EmptyList } from "../../General/views/EmptyList";

const TabbedDatagrid = (props) => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const handleChange = useCallback(
    (event, value) => {
      setFilters &&
        setFilters({ ...filterValues, status: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );
  console.log(handleChange);

  return (
    <Fragment>
      <Divider />
      <ListContextProvider value={{ ...listContext }}>
        <Datagrid
          {...props}
          optimized
          rowClick={false}
          empty={<EmptyList itemName={"Customers"} {...props} />}
        >

          <TextField source="driver.name" label="Applicant Name" />

          <TextField source="recruiter.name" label="Recruiter Name" />
          <FunctionField
            source="charge"
            render={(record) => `$${record?.charge?.toFixed(2)}`}
          />
          <TextField source="charge_status" />
          <TextField source="transfer_status" />
          <DateField source="createdAt" />
        </Datagrid>
      </ListContextProvider>
    </Fragment>
  );
};

export const StaffList = (props) => {
  useAuthenticated();

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      title="transactions"
      filters={<Filters />}
      filterDefaultValues={{ status: "all" }}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <TabbedDatagrid />
    </List>
  );
};
