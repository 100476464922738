import * as React from "react";
import { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { useParams } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { ThemeProvider } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Logo from "../../assets/images/logoLogin.png";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import {
  useRefresh,
  useNotify,
  Notification,
  defaultTheme,
  useRedirect,
} from "react-admin";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        dijiTribe.
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundColor: "#ede8ff",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    img: {
      padding: theme.spacing(2),
      maxWidth: "100%",
      height: "auto",
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centerdiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2, 0),
    marginLeft: "0 !important",
    fontWeight: "500 !important",
    backgroundColor: "#673de6",
    color: "#ffffff",
    border: "2px solid #673de6",
    "&:hover": {
      backgroundColor: "transparent!important",
      borderColor: "#673de6",
      color: "#673de6",
    },
  },
  cssLabel: {
    color: "#202020 !important",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#673de6 !important",
    },
  },
  cssFocused: {
    cssLabel: {
      color: "#ffffff !important",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    color: "#ffffff",
    borderColor: "#202020 !important",
  },
  w_100: {
    width: "85%",
  },
}));

export const ResetPassword = ({ theme }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const { id } = useParams();
  const redirect = useRedirect();
  const [newPassword, setNewPassword] = useState("");
  const [conPassword, setConPassword] = useState("");

  const submit = (e) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_API_URL}/user/restPassword/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        newPassword: newPassword,
        confirmPassword: conPassword,
      }),
      headers: new Headers({ "content-type": "application/json" }),
    })
      .then((response) => response.json())
      .then((response) => {
        notify(response.msg);
        refresh();
        redirect("/");
      })
      .catch((e) => {});
  };

  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={12} md={6} className={classes.image}>
          <img src={Logo} alt="logo" className={classes.w_100} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          className={classes.centerdiv}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                id="password1"
                onChange={(e) => setNewPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                autoComplete="current-password"
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <TextField
                id="password"
                onChange={(e) => setConPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                autoComplete="current-password"
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                fullWidth
                onClick={submit}
                className={classes.submit}
              >
                Update
              </Button>

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
      <Notification />
    </ThemeProvider>
  );
};
