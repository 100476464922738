import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";

let autocompleteService = new window.google.maps.places.AutocompleteService();

const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("auth");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL + "/admin",
  fetchJson
);

const myDataProvider = {
  ...dataProvider,
  getOne: (resource, params) => {
    // if (resource === "admins" && params.id === "me") {
    //   return new Promise(function (resolve, reject) {
    //     resolve({
    //       data: {
    //         id: "me",
    //         email: "zain@thirtynorth.dev",
    //         first_name: "Zain",
    //         last_name: "Ahmad",
    //         phone: "08129828128",
    //       },
    //     });
    //   });
    // }
    return dataProvider.getOne(resource, params);
  },

  getMany: (resource, params) => {},

  getManyReference: (resource, params) => {
    return dataProvider.getManyReference(resource, params);
  },

  getList: (resource, params) => {
    if (
      (resource === "therapist-address" || resource === "customer-address") &&
      params.filter.q &&
      autocompleteService
    ) {
      return new Promise(function (resolve, reject) {
        autocompleteService
          .getPlacePredictions(
            {
              input: params.filter.q ?? "",
              componentRestrictions: { country: "us" },
            },
            (result) => {
              resolve({
                total: result?.length ?? 0,
                data:
                  result?.map(
                    (place) =>
                      ({
                        id: place.place_id,
                        name: place.description,
                      } ?? [])
                  ) ?? [],
              });
            }
          )
          .catch((e) => {
            resolve({
              total: 0,
              data: [],
            });
          });
      });
    } else {
      return dataProvider.getList(resource, params);
    }
  },

  update: (resource, params) => {
    if (resource === "therapists" || resource === "studio-therapists") {
      let formData = new FormData();
      if (
        params.data.license &&
        params.data.license.license_document_url &&
        params.data.license.license_document_url.rawFile
      ) {
        formData.append(
          "license",
          params.data.license.license_document_url.rawFile
        );
        delete params.data.license.license_document_url;
      }
      if (
        params.data.liability_insurance &&
        params.data.liability_insurance.insurance_document_url &&
        params.data.liability_insurance.insurance_document_url.rawFile
      ) {
        formData.append(
          "insurance_document",
          params.data.liability_insurance.insurance_document_url.rawFile
        );
        delete params.data.liability_insurance.insurance_document_url;
      }
      if (
        params.data.identity_verification.identity_document_url &&
        params.data.identity_verification.identity_document_url.rawFile
      ) {
        formData.append(
          "identity_document",
          params.data.identity_verification.identity_document_url.rawFile
        );
        delete params.data.identity_verification.identity_document_url;
      }
      formData.append("data", JSON.stringify(params.data));

      return httpClient(
        `${process.env.REACT_APP_API_URL}/${resource}/${params.id}`,
        {
          method: "PUT",
          body: formData,
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === "homework-categories") {
      let formData = new FormData();

      if (params.data.image && params.data.image.rawFile) {
        formData.append("homework_category_image", params.data.image.rawFile);
      }
      formData.append("name", params.data.name);

      return httpClient(
        `${process.env.REACT_APP_API_URL}/${resource}/${params.id}`,
        {
          method: "PUT",
          body: formData,
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === "promotions") {
      let formData = new FormData();

      if (params.data.banner && params.data.banner.rawFile) {
        formData.append("promotion_banner", params.data.banner.rawFile);
      }
      formData.append(
        "only_for_new_customers",
        params.data.only_for_new_customers
      );
      formData.append("promo_code", params.data.promo_code);
      formData.append("starting_time", params.data.starting_time);
      formData.append("ending_time", params.data.ending_time);
      formData.append("discount_percentage", params.data.discount_percentage);
      formData.append("description", params.data.description);

      return httpClient(
        `${process.env.REACT_APP_API_URL}/${resource}/${params.id}`,
        {
          method: "PUT",
          body: formData,
        }
      ).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else {
      return dataProvider.update(resource, params);
    }
  },

  create: (resource, params) => {
    if (resource === "homework-categories") {
      let formData = new FormData();

      if (params.data.image && params.data.image.rawFile) {
        formData.append("homework_category_image", params.data.image.rawFile);
      }
      formData.append("name", params.data.name);

      return httpClient(`${process.env.REACT_APP_API_URL}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else if (resource === "promotions") {
      let formData = new FormData();

      if (params.data.banner && params.data.banner.rawFile) {
        formData.append("promotion_banner", params.data.banner.rawFile);
      }
      formData.append(
        "only_for_new_customers",
        params.data.only_for_new_customers
      );
      formData.append("promo_code", params.data.promo_code);
      formData.append("starting_time", params.data.starting_time);
      formData.append("ending_time", params.data.ending_time);
      formData.append("discount_percentage", params.data.discount_percentage);
      formData.append("description", params.data.description);

      return httpClient(`${process.env.REACT_APP_API_URL}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else {
      return dataProvider.create(resource, params);
    }
  },
};

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("auth");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export default myDataProvider;
