import * as React from "react";
import { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { Link as Links } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Logo from "../../assets/images/logoLogin.png";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import { useLogin, useNotify, Notification, defaultTheme } from "react-admin";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        dijiTribe.
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    display: "flex",
    backgroundColor: "#ede8ff",
    justifyContent: "center",
    alignItems: "center",
    img: {
      padding: theme.spacing(2),
      maxWidth: "100%",
      height: "auto",
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  centerdiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    color: "#ffffff",
    cursor: "pointer",
    marginLeft: "0!important",
    fontWeight: "500!important",
    backgroundColor: "#673de6",
    border: "2px solid #673de6",
    margin: theme.spacing(3, 0, 2, 0),
    "&:hover": {
      color: "#673de6",
      borderColor: "#673de6",
      backgroundColor: "transparent!important",
    },
  },
  cssLabel: {
    color: "#202020 !important",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#673de6!important",
    },
  },
  cssFocused: {
    cssLabel: {
      color: "#ffffff !important",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    color: "#ffffff",
    borderColor: "#202020 !important",
  },
  w_100: {
    width: "85%",
  },
}));

export const Login = ({ theme }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    login({ email, password }).catch((e) => {
      notify("Invalid email or password provided.", "warning");
    });
  };

  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={12} md={6} className={classes.image}>
          <img src={Logo} alt="logo" className={classes.w_100} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          className={classes.centerdiv}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <form className={classes.form} noValidate>
              <TextField
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />

              <TextField
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />

              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                fullWidth
                onClick={submit}
                className={classes.submit}
              >
                Sign In
              </Button>

              <Typography
                variant="body2"
                color="textSecondary"
                align="right"
                style={{ cursor: "pointer" }}
              >
                <Links to={"/forgotpassword"} style={{ color: "#202020" }}>
                  Forgot Password
                </Links>
              </Typography>

              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
      <Notification />
    </ThemeProvider>
  );
};
