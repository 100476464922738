export const authProvider = {
  // authentication
  login: ({ email, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_URL}/admin/authenticate`,
      {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log({ data });
        localStorage.setItem("auth", data.token);
        localStorage.setItem(
          "full_name",
          data.first_name + " " + data.last_name
        );
        localStorage.setItem("id", data.id);
        localStorage.setItem("role", data.role);
      })
      .catch((e) => {
        throw new Error("Network error");
      });
  },

  // authorization
  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401) {
      return Promise.reject({ logoutUser: true });
    }
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem("auth") ? Promise.resolve() : Promise.reject(),

  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },

  getIdentity: () =>
    Promise.resolve({
      id: localStorage.getItem("id"),
      fullName: localStorage.getItem("first_name"),
    }),
};
