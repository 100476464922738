import React from "react";
import { useSelector } from "react-redux";
import List from "@material-ui/core/List";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { MdDashboard } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { ImUser } from "react-icons/im";
import { BsFillCreditCard2FrontFill } from "react-icons/bs";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      position: "fixed",
      maxWidth: "240px",
      backgroundColor: "#f7f2f2",
      overflow: "auto",
      bottom: "0",
      top: "50px",

      "& .MuiTypography-body1": {
        color: "#202020",
        fontWeight: "500",
      },
    },
    active: {
      borderLeft: "3px solid #673de6",
      background: "rgba(103, 61, 230, 1)",

      "& .MuiTypography-body1": {
        color: "#ffffff",
      },
      "& .sidebar-icons": {
        color: "#ffffff",
      },
    },
    nested: {
      paddingLeft: theme.spacing(3),
    },
  })
);

const Menu = () => {
  const classes = useStyles();
  const sidebarOpen = useSelector((state) => state.admin?.ui.sidebarOpen);

  return (
    <List
      id="sidebar"
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root + (sidebarOpen ? " halfmenuclosed" : "")}
    >
      <ListItem
        button
        component={NavLink}
        to={"/"}
        activeClassName={classes.active}
        exact
      >
        <ListItemIcon>
          <MdDashboard className="sidebar-icons" />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      <ListItem
        button
        component={NavLink}
        to={"/recruiters"}
        activeClassName={classes.active}
      >
        <ListItemIcon>
          <FaUsers className="sidebar-icons" />
        </ListItemIcon>
        <ListItemText primary="Recruiters" />
      </ListItem>

      <ListItem
        button
        component={NavLink}
        to={"/drivers"}
        activeClassName={classes.active}
      >
        <ListItemIcon>
          <ImUser className="sidebar-icons" />
        </ListItemIcon>

        <ListItemText primary="Applicants" />

      </ListItem>

      <ListItem
        button
        component={NavLink}
        to={"/transaction"}
        activeClassName={classes.active}
      >
        <ListItemIcon>
          <BsFillCreditCard2FrontFill className="sidebar-icons" />
        </ListItemIcon>
        <ListItemText primary="Transactions" />
      </ListItem>
    </List>
  );
};

export default Menu;
