import React from "react";
import { TextInput, PasswordInput, required } from "react-admin";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    index: {
      display: "none",
    },
    accordion_details: {
      display: "block",
      padding: "0",
      borderBottom: "0 !important",
      "& .MuiAccordionSummary-root": {
        padding: "0",
        maxWidth: "150px",
      },
    },
    colorTextgrey: {
      color: "#ffffff",
    },
    inputField: {
      width: "100%",
    },
    ptb: {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },
    root: {
      paddingTop: theme.spacing(10),
    },
  })
);

export const BasicDetails = ({ staticContext, ...props }) => {
  const classes = useStyles();

  return (
    <Box component="section" className={`${classes.root} myforms`}>
      <Grid
        style={{ width: "100%", paddingBottom: "50px" }}
        container
        spacing={10}
      >
        <Grid style={{ paddingBottom: "0px" }} item xl={12} md={12} sm={12}>
          <Typography variant="h6" className={classes.colorTextgrey}>
            Account Information
          </Typography>
        </Grid>

        <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
          <TextInput
            source="first_name"
            validate={required()}
            className={classes.inputField}
          />
        </Grid>

        <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
          <TextInput
            source="last_name"
            validate={required()}
            className={classes.inputField}
          />
        </Grid>

        <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
          <TextInput
            source="email"
            validate={required()}
            className={classes.inputField}
          />
        </Grid>

        <Grid className={classes.ptb} item xl={6} md={6} sm={6}>
          <TextInput source="phone" className={classes.inputField} />
        </Grid>
      </Grid>

      <Grid
        key="passwords"
        container
        spacing={10}
        style={{ width: "100%", paddingBottom: "50px" }}
      >
        <Grid style={{ paddingBottom: "0px" }} item xl={12} md={12} sm={12}>
          <Typography variant="h6" className={classes.colorTextgrey}>
            Update Password
          </Typography>
        </Grid>

        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <PasswordInput
            inputProps={{ autocomplete: "false" }}
            source="password"
          />
        </Grid>

        <Grid className={classes.ptb} item xl={4} md={6} sm={6}>
          <PasswordInput
            inputProps={{ autocomplete: "false" }}
            source="confirm_password"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
