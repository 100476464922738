import { useState } from "react";
import {
  Edit,
  useNotify,
  useRedirect,
  useAuthenticated,
  FormWithRedirect,
  // usePermissions,
} from "react-admin";
import { BasicDetails } from "./BasicDetails";
import { Container, createStyles, makeStyles } from "@material-ui/core";
import { EditBottomToolBar } from "../../General/utils/EditBottomToolBar";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
  })
);

const validatePasswords = ({ password, confirm_password }) => {
  const errors = {};

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password =
      "The password confirmation is not the same as the password.";
  }
  return errors;
};

export const MyProfile = (props) => {
  useAuthenticated();
  const redirect = useRedirect();
  // const { permissions: role } = usePermissions();
  const notify = useNotify();

  const onSuccess = ({ data }) => {
    notify(data.message ?? `Your data has been updated successfully.`);
    localStorage.setItem("first_name", data.first_name);
    localStorage.setItem("last_name", data.last_name);
    redirect("my-profile");
  };

  const tabsWithDetails = {
    basic_details: {
      name: "Basic Details",
      component: <BasicDetails {...props} />,
    },
  };

  const tabIds = Object.keys(tabsWithDetails);
  const [currentTabId] = useState(tabIds[0]);
  const classes = useStyles();

  return (
    <Edit
      id="me"
      className={classes.root}
      mutationMode="pessimistic"
      basePath="/my-profile"
      resource="profile"
      redirect={false}
      title="My Profile"
      onSuccess={onSuccess}
      {...props}
    >
      <FormWithRedirect
        {...props}
        validate={validatePasswords}
        render={(formProps) => [
          <Container className="myforms" key="content">
            {tabsWithDetails[currentTabId].component}
          </Container>,
          <EditBottomToolBar key="toolbar" {...formProps} />,
        ]}
      />
    </Edit>
  );
};
