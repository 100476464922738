import * as React from "react";
import { AppBar } from "react-admin";
import { MyUserMenu } from "./UserMenu";
import Logo from "../../assets/images/logoDashboard.png";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiButton-label": {
        color: "#ffffff",
      },
    },
    spacer: {
      flex: 1,
    },
    superChip: {
      color: "#ffffff",
    },
  })
);

const MyAppBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      userMenu={<MyUserMenu />}
      // style={{ background: "#09102A" }}
      className={`${classes.root} appmenu`}
      {...props}
    >
      <img src={Logo} alt="Zuzu Logo" width="150" />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
