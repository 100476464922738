import { useState } from "react";
import { Broadcasts } from "./Broadcasts";
import { BasicDetails } from "./BasicDetails";
import { EditTabs } from "../../General/utils/EditTabs";
import { EditBottomToolBar } from "../../General/utils/EditBottomToolBar";
import { Container, createStyles, makeStyles, Paper } from "@material-ui/core";
import { AsideCustomerProfile } from "../../General/views/AsideCustomerProfile";
import {
  useAuthenticated,
  Edit,
  FormWithRedirect,
  useRecordContext,
} from "react-admin";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",

        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
          maxWidth: "100%",
        },
      },
    },
  })
);

const Aside = () => {
  const record = useRecordContext();
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <AsideCustomerProfile
        key="customer"
        customer={record ?? {}}
        show_minimum={true}
      />
    </Paper>
  );
};

const validatePasswords = ({ password, confirm_password }) => {
  const errors = {};

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password =
      "The password confirmation is not the same as the password.";
  }
  return errors;
};

export const RecruitersEdit = (props) => {
  useAuthenticated();
  const classes = useStyles();
  const tabsWithDetails = {
    basic_details: {
      name: "Basic Details",
      component: <BasicDetails />,
    },

    boradcast: {
      name: "Broadcast",
      component: <Broadcasts />,
    },
  };
  const tabIds = Object.keys(tabsWithDetails);
  const [currentTabId, setCurrentTabId] = useState(tabIds[0]);

  return (
    <Edit
      className={classes.root}
      title="Recruiter Details"
      mutationMode="pessimistic"
      aside={<Aside />}
      {...props}
    >
      <FormWithRedirect
        {...props}
        validate={validatePasswords}
        render={(formProps) => [
          <EditTabs
            key="tabs"
            tabsWithDetails={tabsWithDetails}
            setCurrentTabId={setCurrentTabId}
            currentTabId={currentTabId}
          />,
          <Container key="content">
            {tabsWithDetails[currentTabId].component}
          </Container>,
          <EditBottomToolBar key="toolbar" {...formProps} />,
        ]}
      />
    </Edit>
  );
};
