import { useState } from "react";
import { Avatar, makeStyles, createStyles } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { Confirm, useNotify, useRefresh } from "react-admin";
import { ImageModal } from "../ImageModal";

const title = "Are you sure to upload this image ?";
const content =
  "This image will uploaded to servers and will be saved if confirmed. NOTE: any unsaved edits will be lost as latest information will be refreshed.";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      textAlign: "center",
      width: "270px",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(10),
      marginLeft: theme.spacing(1),
      "@media (max-width:1280px)": {
        width: "217px",
        marginLeft: "5px",
      },
    },
    avatar: {
      width: 90,
      height: 90,
      margin: "auto",
      marginBottom: theme.spacing(5),
    },
    label: {
      position: "relative",
    },
    edit: {
      position: "absolute",
      right: "-50px",
      zIndex: "1",
      color: "#ffffff",
      background: "grey",
      borderRadius: "50%",
      width: "24px",
      height: "24px",
      padding: "5px",
      cursor: "pointer",
    },
  })
);

const CustomAvatar = ({ default_source, name = "" }) => {
  const classes = useStyles();
  const [imageToDisplay, setImageToDisplay] = useState(null);

  return (
    <div className={classes.avatar}>
      {imageToDisplay ? (
        <ImageModal
          alt={"Profile Picture"}
          src={imageToDisplay}
          onClose={() => {
            setImageToDisplay(null);
          }}
        />
      ) : null}

      {default_source ? (
        <Avatar
          style={{ cursor: "pointer" }}
          onClick={() =>
            setImageToDisplay(
              `${process.env.REACT_APP_S3_MEDIA_URL}/${default_source}`
            )
          }
          alt={`Profile Picture of ${name}`}
          src={default_source}
          className={classes.avatar}
        />
      ) : (
        <Avatar className={classes.avatar}>{name[0] ?? "X"}</Avatar>
      )}
    </div>
  );
};

export const AvatarWithInput = ({
  id,
  default_source,
  resource,
  name = "",
  is_editable = false,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const [selectedFile, selectFile] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const token = localStorage.getItem("auth");

  if (!is_editable) {
    return <CustomAvatar default_source={default_source} name={name} />;
  }

  const uploadFile = async () => {
    const formData = new FormData();

    formData.append("company_logo", selectedFile);
    fetch(
      `${process.env.REACT_APP_API_URL}/admin/recruiters/companyLogo/${id}`,

      {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        notify(response.message);
        refresh();
        selectFile(null);
      })
      .catch((e) => {})
      .finally(() => {
        closeConfirmationPopup();
      });
  };

  const handleSelect = (event) => {
    var file = event.target.files[0];
    console.log({ openConfirmation });
    setOpenConfirmation(true);
    selectFile(file);
  };

  const closeConfirmationPopup = () => {
    document.getElementById("contained-button-file").value = "";
    setOpenConfirmation(false);
  };

  return [
    <input
      key={`avatar-input-file-${id}`}
      accept="image/*"
      id="contained-button-file"
      style={{ display: "none" }}
      type="file"
      onChange={handleSelect}
    />,
    <label
      key={`avatar-input-label-${id}`}
      className={classes.label}
      htmlFor="contained-button-file"
    >
      <CreateIcon className={classes.edit} />
    </label>,
    <CustomAvatar
      key={`avatar-input-image-${id}`}
      default_source={default_source}
      name={name}
    />,
    <Confirm
      key={`confirmation-popup-${id}`}
      isOpen={openConfirmation}
      title={title}
      content={content}
      onConfirm={uploadFile}
      onClose={closeConfirmationPopup}
    />,
  ];
};
