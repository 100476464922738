import {
  Tab,
  Show,
  FunctionField,
  useAuthenticated,
  TabbedShowLayout,
  useRecordContext,
} from "react-admin";
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import moment from "moment";
import { Logs } from "./Logs";
import { titleCase } from "../../../functions/string";
import { AsideCustomerProfile } from "../../General/views/AsideCustomerProfile";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginLeft: theme.spacing(2),
    },
    root: {
      "& .MuiTabs-root": {
        paddingBottom: "0px",
        "& .MuiButtonBase-root": {
          flex: "1",
          borderBottom: "1px solid #4C4B4C",
        },
      },
    },
    colorTextgrey: {
      color: "#e0d9d9",
    },
  })
);

const Aside = () => {
  const record = useRecordContext();
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <AsideCustomerProfile
        key="customer"
        customer={record ?? {}}
        show_minimum={true}
      />
    </Paper>
  );
};

export const JobShow = (props) => {
  useAuthenticated();
  const classes = useStyles();

  return (
    <Show
      className={classes.root}
      title="Broadcast Detail"
      aside={<Aside />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="Basic Details">
          <Grid container style={{ marginBottom: "50px" }}>
            <Grid style={{ marginTop: "50px" }} item xs={12}>
              <Typography variant="p" className={classes.colorTextgrey}>
                Status
              </Typography>

              <FunctionField
                label="Status"
                render={(record) => (
                  <Typography variant="h6">{record.status}</Typography>
                )}
              />
            </Grid>

            <Grid style={{ marginTop: "50px" }} item xs={12}>
              <Typography variant="p" className={classes.colorTextgrey}>
                Description
              </Typography>

              <FunctionField
                label="Description"
                render={(record) => (
                  <Typography variant="h6">
                    {titleCase(record.description) || "No Preference"}
                  </Typography>
                )}
              />
            </Grid>

            <Grid style={{ marginTop: "50px" }} item xs={12}>
              <Typography variant="p" className={classes.colorTextgrey}>
                Created At
              </Typography>

              <FunctionField
                label="Massage Time"
                render={(record) => (
                  <Typography variant="h6">
                    {record.is_immediate
                      ? `Immediately Requested`
                      : moment(record.local_massage_time).format("LLLL")}
                  </Typography>
                )}
              />
            </Grid>
          </Grid>
        </Tab>
        <Tab label="Logs">
          <Logs />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
