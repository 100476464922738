import { createTheme as createMuiTheme } from "@material-ui/core/styles";

const defaults = {
  charcoalColor: { root: "#ffffff" },
  tealColor: {
    main: "#673de6",
    light: "#42ffe2",
    dark: "#269483",
  },
  pinkColor: {
    main: "#F46868",
    light: "#F46868",
    dark: "#F46868",
  },
  greenColor: {
    main: "#1DC3A7",
    light: "#f89406",
    dark: "#f89406",
  },
};

export const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    h1: {
      "@media (max-width:1280px)": {
        fontSize: "24px !important",
      },
    },
    h3: {
      color: "#202020",
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: "bold",
    },
    h5: {
      color: "#202020",
    },
    h6: {
      fontWeight: "normal",
      color: "#202020!important",
      "@media (max-width:1280px)": {
        fontSize: "16px !important",
      },
    },
    body1: {
      color: "#202020",
      textDecoration: "none",
      "@media (max-width:1280px)": {
        fontSize: 14,
      },
    },
  },

  palette: {
    type: "light",
    primary: defaults.tealColor,
    secondary: defaults.tealColor,
    invisible: defaults.charcoalColor.root,
    error: defaults.pinkColor,
    solved: defaults.greenColor,
    warning: defaults.greenColor,
  },

  overrides: {
    RaToolbar: {
      toolbar: {
        backgroundColor: defaults.charcoalColor.root,
      },
    },

    RaLayout: {
      content: {
        backgroundColor: "#ffffff",
        zIndex: "9999",
      },
    },

    MuiPaper: {
      root: {
        backgroundColor: defaults.charcoalColor.root,
      },
    },

    RaButton: {
      button: {
        width: defaults.buttonWidth,
      },
    },

    RaDeleteButton: {
      root: {
        backgroundColor: defaults.charcoalColor.root,
      },
    },
  },
});
